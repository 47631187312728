var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"deliveryDetails"},[_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Pick Up Date'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Pick Up Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Choose Date","readonly":"readonly","config":{
                minDate: 'today'
              }},model:{value:(_vm.form.pickup_date),callback:function ($$v) {_vm.$set(_vm.form, "pickup_date", $$v)},expression:"form.pickup_date"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'I am'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"I am"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-radio-group',{model:{value:(_vm.form.shipmentType),callback:function ($$v) {_vm.$set(_vm.form, "shipmentType", $$v)},expression:"form.shipmentType"}},[_c('b-form-radio',{attrs:{"value":"buyer"}},[_vm._v("Buyer")]),_c('b-form-radio',{attrs:{"value":"seller"}},[_vm._v("Seller")])],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Incoterm'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Incoterm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-checkbox-group',{model:{value:(_vm.form.movementArrangement),callback:function ($$v) {_vm.$set(_vm.form, "movementArrangement", $$v)},expression:"form.movementArrangement"}},_vm._l((_vm.movementArrangementOptions),function(option,index){return _c('b-form-checkbox',{key:index,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" "),_vm._t("label",function(){return [_c('feather-icon',{staticStyle:{"margin-bottom":"5px"},attrs:{"id":("size-tooltip-" + index),"size":"20","icon":"InfoIcon","variant":'primary'}}),_c('b-tooltip',{attrs:{"target":("size-tooltip-" + index),"triggers":"hover","no-fade":""}},[_vm._v(" "+_vm._s(option.description)+" ")])]})],2)}),1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Delivery Type'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Delivery Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-radio-group',{model:{value:(_vm.form.deliveryType),callback:function ($$v) {_vm.$set(_vm.form, "deliveryType", $$v)},expression:"form.deliveryType"}},[_c('b-form-radio',{attrs:{"value":"Port to Port"}},[_vm._v("Port to Port")]),_c('b-form-radio',{attrs:{"value":"Door to Door"}},[_vm._v("Door to Door")])],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }