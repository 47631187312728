<template>
    <div>
      <b-form-group :label="title">
        <p type="text" class="rounded border p-1 mr-2 d-flex align-items-center">
          {{ data }}
        </p>
      </b-form-group>
    </div>
  </template>
  <script>
  export default {
    props: {
      title: String,
      data: String,
    },
  };
  </script>
  