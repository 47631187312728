var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Sender Details")]),_c('ValidationObserver',{ref:"senderDetails"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Name (or Company Name)'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Name (or Company Name)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.name),expression:"sender.name"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Name (or Company Name)","aria-label":"Name (or Company Name)"},domProps:{"value":(_vm.sender.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender, "name", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('email')}},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":"E-mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.email),expression:"sender.email"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"E-mail","aria-label":"E-mail"},domProps:{"value":(_vm.sender.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Contact Number'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Contact Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.phone),expression:"sender.phone"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Contact Number","aria-label":"Contact Number"},domProps:{"value":(_vm.sender.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender, "phone", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.deliveryType === 'Door to Door')?_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Unit Number'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Unit Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address.unitNumber),expression:"address.unitNumber"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Unit Number","aria-label":"Unit Number"},domProps:{"value":(_vm.address.unitNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.address, "unitNumber", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,105794401)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Address Line 1'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Address Line 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address.addressLine1),expression:"address.addressLine1"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Address Line 1","aria-label":"Address Line 1"},domProps:{"value":(_vm.address.addressLine1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.address, "addressLine1", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,922483777)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Address Line 2'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Address Line 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address.addressLine2),expression:"address.addressLine2"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Address Line 2","aria-label":"Address Line 2"},domProps:{"value":(_vm.address.addressLine2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.address, "addressLine2", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,280121761)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Postal Code'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Postal Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address.postalCode),expression:"address.postalCode"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Postal Code","aria-label":"Postal Code"},domProps:{"value":(_vm.address.postalCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.address, "postalCode", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,401093281)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Area'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address.area),expression:"address.area"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Area","aria-label":"Area"},domProps:{"value":(_vm.address.area)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.address, "area", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,665356353)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'State'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"State"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address.state),expression:"address.state"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"State","aria-label":"State"},domProps:{"value":(_vm.address.state)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.address, "state", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3738840353)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Country'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address.country),expression:"address.country"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Country","aria-label":"Country"},domProps:{"value":(_vm.address.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.address, "country", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1821682337)})],1)],1)],1)],1):_vm._e(),(_vm.deliveryType !== 'Door to Door')?_c('div',[_c('h2',[_vm._v(" Origin Port "),_vm._t("label",function(){return [_c('feather-icon',{staticStyle:{"margin-bottom":"5px"},attrs:{"id":"size-tooltip","size":"20","icon":"InfoIcon","variant":'primary'}}),_c('b-tooltip',{attrs:{"target":"size-tooltip","triggers":"hover","no-fade":""}},[_vm._v(" Choose a port from port name and the fields will auto complete itself. ")])]})],2),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Country'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.port,"clearable":false,"label":"country"},on:{"input":_vm.handleCountryChange},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,665575019)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Port Name'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Port Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.ports,"clearable":false,"label":"name"},model:{value:(_vm.origin),callback:function ($$v) {_vm.origin=$$v},expression:"origin"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1538682386)})],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }