<template>
  <div>
    <ValidationObserver ref="deliveryDetails"
      ><b-row class="d-flex justify-content-center">
        <b-col md="6">
          <b-form-group :label="'Pick Up Date'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Pick Up Date"
            >
              <flat-pickr
                v-model="form.pickup_date"
                class="form-control"
                placeholder="Choose Date"
                readonly="readonly"
                :config="{
                  minDate: 'today'
                }"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group> </b-col
        ><b-col md="6">
          <b-form-group :label="'I am'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="I am"
            >
              <b-form-radio-group v-model="form.shipmentType">
                <b-form-radio value="buyer">Buyer</b-form-radio>
                <b-form-radio value="seller">Seller</b-form-radio>
              </b-form-radio-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col> </b-row
      ><b-row>
        <b-col md="6">
          <b-form-group :label="'Incoterm'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Incoterm"
            >
              <b-form-checkbox-group v-model="form.movementArrangement">
                <b-form-checkbox
                  v-for="(option, index) in movementArrangementOptions"
                  :key="index"
                  :value="option.value"
                >
                  {{ option.label }}
                  <slot name="label">
                    <feather-icon
                      :id="`size-tooltip-${index}`"
                      style="margin-bottom: 5px"
                      size="20"
                      icon="InfoIcon"
                      :variant="'primary'"
                    />
                    <b-tooltip
                      :target="`size-tooltip-${index}`"
                      triggers="hover"
                      no-fade
                    >
                      {{ option.description }}
                    </b-tooltip>
                  </slot>
                </b-form-checkbox>
              </b-form-checkbox-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="'Delivery Type'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Delivery Type"
            >
              <b-form-radio-group v-model="form.deliveryType">
                <b-form-radio value="Port to Port">Port to Port</b-form-radio>
                <b-form-radio value="Door to Door">Door to Door</b-form-radio>
              </b-form-radio-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'DeliveryDetails',
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    typeOfOrder: {
      type: String,
      default: 'Sea'
    },
    index: {
      type: Number,
      default: 0
    },
    port: {
      type: Array
    }
  },
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'mr-2'
      },
      selectedMovementArrangement: [],
      form: {},
      movementArrangementOptions: [
        {
          value: 'EXW',
          label: 'EXW',
          description:
            'Ex works (EXW) the buyer pays for all aspects of the shipping process. This includes loading charges, delivery to port, export duties and taxes, origin terminal charges, loading on carriage, carriage charges, destination terminal charges, delivery to destination, and import duty and taxes.'
        },
        {
          value: 'FCA',
          label: 'FCA',
          description:
            "The FCA (Free Carrier) rule requires the seller to deliver the goods to the buyer or its carrier either at the seller's premises loaded onto the collecting vehicle or delivered to another premises (typically a forwarder's warehouse, airport or container terminal) not unloaded from the seller's vehicle."
        },
        {
          value: 'FAS',
          label: 'FAS',
          description:
            "Free Alongside Ship (FAS) is a term used in overseas shipping that denotes delivery has been made when the goods have been offloaded from the seller's ship and cleared through export customs. Under FAS, the buyer is responsible for the cost of clearing export and unloading."
        },
        {
          value: 'FOB',
          label: 'FOB',
          description:
            '"Free on Board (FOB) is a term used to indicate when the ownership of goods transfers from buyer to seller and who is liable for goods damaged or destroyed during shipping. "FOB Origin" means the buyer assumes all risk once the seller ships the product.'
        },
        {
          value: 'CFR',
          label: 'CFR',
          description:
            'Cost and freight (CFR) is an expense associated with cargo transported by sea or inland waterways. If CFR is included in a transaction, the seller must arrange and pay for transporting the cargo to a specified port.'
        },
        {
          value: 'CIF',
          label: 'CIF',
          description: `Cost, insurance, and freight (CIF) is an international shipping agreement, which represents the charges paid by a seller to cover the costs, insurance, and freight of a buyer's order while the cargo is in transit. Cost, insurance, and freight only applies to goods transported via a waterway, sea, or ocean.`
        },
        {
          value: 'CPT',
          label: 'CPT',
          description: `Carriage Paid To (CPT) is an international commercial term (Incoterm) denoting that the seller incurs the risks and 
costs associated with delivering goods to a carrier to an agreed-upon destination. With multiple carriers, the risks and 
costs transfer to the buyer upon delivery to the first carrier.`
        },
        {
          value: 'CIP',
          label: 'CIP',
          description: `CIP (Carriage And Insurance Paid To) means that the seller is responsible for delivery, delivery costs, and 
insurance costs of the goods until they are transferred to the first carrier tasked with transporting the goods. Once 
this delivery takes place, the buyer takes on all responsibility.`
        },
        {
          value: 'DAP',
          label: 'DAP',
          description: `Delivered At Place (DAP) Incoterms rules, the seller is responsible for delivery of the goods, ready for unloading, at 
the named place of destination. The seller assumes all risks involved up to unloading.`
        },
        {
          value: 'DDP',
          label: 'DDP',
          description: `Delivery Duty Paid (DDP) shipping is where the seller takes all responsibility for fees and risks of shipping goods until they are delivered to an agreed place by the buyer and seller`
        },
        {
          value: 'DPU',
          label: 'DPU',
          description: `Delivered at Place Unloaded (DPU) (formerly referred to as DAT for “Delivered at Terminal”) requires the seller to deliver the goods at the disposal of the buyer after they've been unloaded from the arriving means of transport`
        }
      ]
    }
  },
  methods: {
    childMethod() {
      return new Promise((resolve, reject) => {
        var result = ''
        this.$refs.deliveryDetails.validate().then((success) => {
          if (success) {
            this.$emit('setForm', this.form)
            result = true
            resolve(result)
          } else {
            result = false
          }
          resolve(result)
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
